import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import Styles from "../styles/Registration.module.scss";
import SEO from "../components/seo"
import MediaQuery from "react-responsive";
import InputForm from "../components/atoms/InputForm";
import GeneralButton from "../components/atoms/GeneralButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Modal from "../components/Modal";
import Image from "../components/image"
import amplitude, { getUserPropertyForAmplitude, logErrorEvent, logEvent } from "../Analytics";
import ffmpeg from "../utils/FFmpeg"
import { iOSApplicationURL, AndroidApplicationURL } from "../constant/URL"
import { getInitialStateFromSessionStorage } from "../utils/getInitialStateFromSessionStorage"
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { useDispatch } from "react-redux";
import { register } from "../apis/user";
import { useRedirectWhenLoggedIn } from "../hooks/useRedirectWhenLoggedIn";

type errorMessageType = {
    email?: string
    password?: string
}

const RegistrationPage = () => {

    const [ email, setEmail ] = useState( getInitialStateFromSessionStorage( "registration_email", "" ) )
    const [ password, setPassword ] = useState( getInitialStateFromSessionStorage( "registration_password", "" ) )
    const [ uploading, setUploading ] = useState( false )
    const [ errorMessage, setErrorMessage ] = useState<errorMessageType>( {} )
    const [ termAgree, setTermAgree ] = useState( getInitialStateFromSessionStorage( "registration_term_agree", false ) )
    const [ recommendModalVisible, setRecommendModalVisible ] = useState( false )
    useRedirectWhenLoggedIn()
    const dispatch = useDispatch();

    useEffect( () => {
        setTimeout( () => { setRecommendModalVisible( true ) }, 300 )
    }, [] )

    const submit = async () => {
        if ( !termAgree ) {
            alert( "利用規約に同意してください" )
            return
        }
        setUploading( true )
        try {
            const { data } = await register( { email, password } )
            localStorage.setItem( "userID", data.id )
            sessionStorage.removeItem( "registration_email" )
            sessionStorage.removeItem( "registration_password" )
            sessionStorage.removeItem( "registration_term_agree" )
            setTimeout( () => {
                dispatch( { type: "setIsLoggedIn", isLoggedIn: true } )
                dispatch( { type: "setUserUuid", userUuid: data.id } )
                dispatch( { type: "setTeamData", teamData: data.team_data } )
                dispatch( { type: "setHasCompletedRegistration", hasCompletedRegistration: false } )
            }, 3000 )
            const newAmplitude = await getUserPropertyForAmplitude( amplitude )
            logEvent( newAmplitude, "Action-Registration", { web: true } )
            setUploading( false )
            ffmpeg.init()
            navigate( "/welcome" )
        }
        catch ( error ) {
            setUploading( false )
            const emailErrorMessageArray = error.response.data.email || []
            const passwordErrorMessageArray = error.response.data.password || []
            const errorMessageObject: errorMessageType = { email: "", password: "" }
            emailErrorMessageArray.forEach( ( m: string ) => errorMessageObject.email += m + '\n' )
            passwordErrorMessageArray.forEach( ( m: string ) => errorMessageObject.password += m + '\n' )
            setErrorMessage( errorMessageObject )
            logErrorEvent( amplitude, "Error-Registration", error )
        }
    }

    const onChange = ( name: string, value: string ) => {
        if ( name === "email" ) {
            setEmail( value )
            sessionStorage.setItem( "registration_email", JSON.stringify( value ) )
        }
        else if ( name === "password" ) {
            setPassword( value )
            sessionStorage.setItem( "registration_password", JSON.stringify( value ) )
        }
    }

    const getContent = ( iOS: boolean, Android: boolean, PWA: boolean, PC: boolean ) => {
        if ( iOS ) {
            return (
                <div>
                    <h1 className={ Styles.heading }>iOSアプリをリリースしました！</h1>
                    <div style={ { textAlign: "center" } }>
                        <OutboundLink href={ iOSApplicationURL }>
                            <div className={ Styles.appstore_icon } style={ { marginBottom: 16 } }>
                            </div>
                            <p className={ Styles.paragraph }>アプリはこちらから</p>
                        </OutboundLink>
                    </div>
                </div>
            )
        }
        else if ( Android ) {
            return (
                <div>
                    <h1 className={ Styles.heading }>Androidアプリを<br />リリースしました！</h1>
                    <div style={ { textAlign: "center" } }>
                        <OutboundLink href={ AndroidApplicationURL }>
                            <div className={ Styles.google_play_icon } style={ { marginBottom: 16 } }>
                            </div>
                            <p className={ Styles.paragraph }>アプリはこちらから</p>
                        </OutboundLink>
                    </div>
                </div>
            )
        }
        // else if( PWA ) {
        // 	return (
        // 		<div>
        // 			<h1 className={ Styles.heading } style={ { marginBottom: 24 } }>アプリのように使えます！</h1>
        // 			<p className={ Styles.paragraph }>インストールの仕方</p>
        // 			<ul>
        // 				<li>1. GoogleChromeでこのページを開きます</li>
        // 				<li>2. 右上のメニューボタンを開く</li>
        // 				<li>3.「ホーム画面に追加」を選択</li>
        // 			</ul>
        // 			<div className={ Styles.image_container }>
        // 				<Image filename="A2HS.jpg" style={ { height: "50vh", objectFit: "contain" } } />
        // 			</div>
        // 		</div>
        // 	)
        // }
        else if ( PC || PWA ) {
            return (
                <div>
                    <h1 className={ Styles.heading }>iPhone と Android でアプリリリースしました！</h1>
                    <div className={ Styles.paragraph }>
                        <p style={ { marginBottom: 8, color: "#fff" } }>下記のQRコードをスマホのカメラで写してください</p>
                        <Image filename="QR-lead_app.png" style={ { height: 150, width: 150, margin: "auto", marginBottom: 16 } } />
                        <div className={ Styles.PC_icon_container }>
                            <OutboundLink href={ iOSApplicationURL }>
                                {/* <p className={ Styles.indicate_store }>iPhoneはこちら</p> */ }
                                <div className={ Styles.appstore_icon } style={ { marginBottom: 16 } }>
                                </div>
                                {/* <Image filename="QR-iOS.png" style={ { height: 150, width: 150, margin: "auto"}}/> */ }
                            </OutboundLink>
                            {/* </div>
						<div> */}
                            <OutboundLink href={ AndroidApplicationURL }>
                                {/* <p className={ Styles.indicate_store }>Androidはこちら</p> */ }
                                <div className={ Styles.google_play_icon } style={ { marginBottom: 16 } }>
                                </div>
                                {/* <Image filename="QR-Android.png" style={ { height: 150, width: 150, margin:"auto" } }/> */ }
                            </OutboundLink>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderModal = () => {
        // パターン0 android PWA => なし
        // パターン1 ios => アプリ勧める
        // パターン2 android かつ web => PWA勧める
        // パターン3 PC => アプリの告知
        let iOS = null
        let Android = null
        let PWA = null
        let PC = null
        if ( typeof window !== "undefined" ) {
            PWA = window.matchMedia( '(display-mode: standalone)' ).matches || ( window.navigator as any ).standalone
            if ( !PWA && ( window.screen.height - document.documentElement.clientHeight < 80 ) ) { //PWAだとアドレスバーがないので
                PWA = true
            }
            // alert(JSON.stringify([PWA, window.screen.height - document.documentElement.clientHeight ]))
            iOS = navigator.userAgent.match( /(iPhone|iPad|iPod)/ )
            Android = navigator.userAgent.match( /(Android)/ )
            PC = !Boolean( iOS || Android )
        }

        let modalContent = getContent( !!iOS, !!Android, !!PWA, !!PC )
        // console.log(navigator.userAgent.match(/(iPhone|iPad|iPod)/),"eee")

        // typeof window !== 'undefined' && alert(JSON.stringify([iOS, Android, PC, PWA]))
        // if( PWA ) {
        // 	return ( <></> )
        // }
        if ( iOS || Android || PC || PWA ) {
            return (
                <Modal
                    visible={ recommendModalVisible }
                    // className={ Styles.reccomend_modal }
                    closeModal={ () => setRecommendModalVisible( false ) }>
                    <div>
                        { modalContent }
                    </div>
                </Modal>
            )
        }
        return null
    }

    const renderRecommendModalVisibleButtun = () => {
        if ( typeof window !== "undefined" ) {
            let PWA = window.matchMedia( '(display-mode: standalone)' ).matches || ( window.navigator as any ).standalone
            if ( !PWA && ( window.screen.height - document.documentElement.clientHeight < 80 ) ) { //PWAだとアドレスバーがないので
                PWA = true
            }
            let Android = navigator.userAgent.match( /(Android)/ )
            if ( Android && !PWA ) {
                return (
                    <p className={ Styles.show_install_procedure } onClick={ () => setRecommendModalVisible( true ) }>アプリにする方法はこちら</p>
                )
            }
        }
        return <></>
    }

    return (
        <div className={ Styles.container_wrapper }>
            <SEO title="Registration" />
            <div className={ Styles.container }>
                <div>
                    <h1 className={ Styles.title }>ClubCloud</h1>
                    <div className={ Styles.registration_box }>
                        <div className={ Styles.input_wrapper }>
                            <InputForm
                                onChange={ onChange }
                                label="メールアドレス"
                                name="email"
                                placeholder="sample@mail.com"
                                value={ email }
                                type="text"
                                labelStyle={ { fontWeight: 'normal', } }
                                inputFormStyle={ { borderRadius: 4, padding: 8 } }
                                errorMessage={ errorMessage.email } />
                        </div>
                        <div className={ Styles.input_wrapper }>
                            <InputForm
                                onChange={ onChange }
                                label="パスワード"
                                name="password"
                                placeholder="8文字以上"
                                value={ password }
                                type="password"
                                labelStyle={ { fontWeight: 'normal', } }
                                inputFormStyle={ { borderRadius: 4, padding: 8 } }
                                errorMessage={ errorMessage.password }
                                displayPasswordCondition={ true } />
                        </div>
                        <p className={ Styles.agree_to_terms }>
                            <FontAwesomeIcon
                                icon={ termAgree ? faCheckSquare : faSquare }
                                onClick={ () => { setTermAgree( !termAgree ); sessionStorage.setItem( "registration_term_agree", JSON.stringify( !termAgree ) ) } }
                                style={ { marginRight: 8, fontSize: "1.3rem", color: termAgree ? "#555" : "#ccc", cursor: "pointer" } } />
                            <Link to="/terms" style={ { marginBottom: "16px" } }>利用規約</Link>
                            と
						    <Link to="/privacy_policy" style={ { marginBottom: "48px" } }>プライバシーポリシー</Link>
                            に同意する
				        </p>
                        <GeneralButton
                            onClick={ submit }
                            loading={ uploading }
                            title="無料登録"
                            style={ { padding: "8px 36px", width: "100%" } } />
                    </div>
                    <div style={ { display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" } }>
                        <Link to="/login" style={ { marginBottom: 24 } }>ログインはこちら</Link>
                        <Link to="/" style={ { marginBottom: 24 } }>トップページ</Link>
                    </div>
                    { renderRecommendModalVisibleButtun() }
                    { renderModal() }
                </div>
            </div>
            <MediaQuery query="(min-width: 900px)">
                <div className={ Styles.image_container }>
                    <Image filename="top_compare.png" style={ { width: "40vw", height: "70vh" } } />
                </div>
            </MediaQuery>
        </div >
    )
}

export default RegistrationPage
